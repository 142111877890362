export default `
  @import url('https://applidget.github.io/vx-assets/orgs/madyness/fonts/stylesheet.css');
  :root {
    --color-primary: #eeff8a;
    --color-border: #000;
    --font-body: Agrandir, "Open Sans", Helvetica, Arial, sans-serif;
  }

  body {
    font-family: "Agrandir", sans-serif;
    font-weight: 400;
    letter-spacing: 0.01em;
  }

  .p3-widget.p3-layout-landscape .p3-wrapper {
    background-color: var(--color-primary);
    border: 7px solid var(--color-border);
  }

  .p3-widget.p3-layout-landscape .p3-wrapper .p3-inner .p3-container {
    max-width: 640px;
  }

  .p3-widget .p3-wrapper .p3-inner .p3-container {
    padding: 3.5rem;
  }

  .p3-widget-newsletter form.p3-form {
    width: 100%;
    max-width: unset;
    display: grid;
    gap: 10px;
    grid-template-areas:
      "field field field button"
      "error error error error"
      "optin optin optin optin";
  }

  .p3-widget-newsletter form.p3-form .junipero.field.text-input {
    grid-area: field;
  }

  .p3-widget-newsletter form.p3-form .junipero.field.text-input input {
    background: white;
    border-radius: 0;
    border: 0;
    border-bottom: 5px solid rgb(62, 104, 255);
    transition: background-color .2s, border-color .2s;
  }

  .p3-widget-newsletter form.p3-form .junipero.field.text-input input:focus {
    background: white;
    border-color: black;
  }

  .p3-widget-newsletter form.p3-form .junipero.field.text-input.dirty:not(.has-error) input:not(:focus) {
    background: white;
    border-color: rgb(62, 104, 255);
  }

  .p3-widget-newsletter form.p3-form .p3-optin {
    grid-area: optin;
    display: block;
    margin-bottom: 0;
    margin-top: 0;
  }

  .p3-widget-newsletter form.p3-form .p3-optin .junipero.field.checkbox {
    border-right: 0;
    padding-right: 0;
    margin-right: 0;
    margin-bottom: 0;
  }

  .p3-widget-newsletter form.p3-form .p3-optin .junipero.field.checkbox .content {
    font-size: 13px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.7);
  }

  .p3-widget-newsletter form.p3-form .p3-optin .p3-data-policy {
    padding-left: 26px;
    max-width: unset;
  }

  .p3-widget-newsletter .p3-full-widget .p3-button-wrapper {
    grid-area: button;
    margin-bottom: 0;
    margin-top: 0;
  }

  .p3-widget-newsletter form.p3-form .p3-alert {
    grid-area: error;
  }

  .p3-widget-form.p3-layout-landscape form.p3-form, .p3-widget-newsletter.p3-layout-landscape form.p3-form, .p3-widget-pass.p3-layout-landscape form.p3-form {
    margin: 0;
    width: auto;
  }

  .p3-widget-form.p3-layout-landscape form.p3-form .p3-optin .p3-form-group.p3-data-policy, .p3-widget-newsletter.p3-layout-landscape form.p3-form .p3-optin .p3-form-group.p3-data-policy, .p3-widget-pass.p3-layout-landscape form.p3-form .p3-optin .p3-form-group.p3-data-policy {
    margin-left: 0;
  }

  a.p3-button, button.p3-button, .p3-widget-newsletter.p3-layout-landscape form.p3-form button.p3-main-action {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    padding: 8px 32px;
    font-family: "Agrandir", sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    background-color: #fff;
    color: black;
    border: 1px solid black;
    border-radius: 0;
    box-shadow: 5px 5px #3e68ff;
    transition: background-color .2s, color .2s;
    text-shadow: none;
  }

  a.p3-button:hover, button.p3-button:not(:disabled):hover, .p3-widget-newsletter.p3-layout-landscape form.p3-form button.p3-main-action:not(:disabled):hover {
    color: #fff;
    text-decoration: none;
    background-color: #3e68ff;
    box-shadow: 5px 5px #000;
  }

  button.p3-button:disabled, .p3-widget-newsletter.p3-layout-landscape form.p3-form button.p3-main-action:disabled {
    background-color: #fff;
    text-shadow: none;
    cursor: not-allowed;
  }

  .p3-widget.p3-layout-landscape a.p3-button, .p3-widget.p3-layout-landscape button.p3-button {
    font-size: 13px;
    box-shadow: 5px 5px #3e68ff;
    margin: 0 0 16px 0;
  }

  .p3-widget.p3-layout-landscape a.p3-button:hover, .p3-widget.p3-layout-landscape button.p3-button:not(:disabled):hover {
    box-shadow: 5px 5px #000;
  }

  .p3-widget-form .p3-wrapper .p3-inner .p3-container button.p3-main-action, .p3-widget-newsletter .p3-wrapper .p3-inner .p3-container button.p3-main-action, .p3-widget-pass .p3-wrapper .p3-inner .p3-container button.p3-main-action {
    width: auto;
  }

  .p3-widget.p3-layout-landscape .p3-wrapper .p3-inner .p3-container .p3-subactions a.p3-link {
    border: 0;
    flex-grow: unset;
    width: auto;
    text-decoration: underline;
  }

  .p3-widget.p3-layout-landscape .p3-wrapper .p3-inner .p3-container .p3-action .p3-button, .p3-widget.p3-layout-landscape .p3-wrapper .p3-inner .p3-container .p3-subactions .p3-button {
    width: auto;
  }

  .p3-widget.p3-layout-landscape .p3-wrapper .p3-inner .p3-container .p3-subactions a.p3-link:hover {
    background: transparent;
    color: #3e68ff;
    text-decoration: none;
  }

  .p3-widget.p3-layout-landscape .p3-wrapper .p3-inner .p3-container .p3-action, .p3-widget.p3-layout-landscape .p3-wrapper .p3-inner .p3-container .p3-subactions {
    width: auto;
    margin-top: 1rem;
  }

  .p3-widget.p3-layout-landscape .p3-wrapper .p3-inner .p3-container .p3-subactions a.p3-link.p3-subscribe:hover {
    border: 0 !important;
    background: transparent;
    color: #3e68ff;
  }

  .p3-widget-newsletter.p3-layout-landscape form.p3-form button.p3-main-action {
    margin-bottom: 5px;
  }

  .p3-widget .p3-wrapper .p3-inner .p3-container h3.p3-title {
    margin-bottom: 12px;
    font-size: 22px;
    font-weight: 700;
    line-height: 26px;
  }

  .p3-widget .p3-wrapper .p3-inner .p3-container p.p3-text {
    margin-bottom: 38px;
    font-size: 24px;
    line-height: 29px;
  }
`;
