import { AbstractService } from './AbstractService.js';

/**
 * @typedef {{
 *  account?: string|null,
 *  signin?: string|null,
 *  subscribe?: string|null,
 * }} Urls
 */

/**
 * SubscribeService class.
 */
export default class SubscribeService extends AbstractService {
  static script = 'https://assets.poool-subscribe.fr/subscribe.js';

  static targets = {
    auth: Array.from(document.querySelectorAll('[data-poool-auth]')),
    signin: Array.from(document.querySelectorAll('[data-poool-signin]')),
    subscribe: Array.from(document.querySelectorAll('[data-poool-subscribe]')),
    signout: Array.from(document.querySelectorAll('[data-poool-signout]')),
    account: Array.from(document.querySelectorAll('[data-poool-account]')),
  };

  subscribe = null;

  pageType = 'free';

  /**
   * @type {Urls}
   */
  urls = {
    account: null,
    signin: null,
    subscribe: null,
  };

  user = null;

  /**
   * Define urls.
   * @param {Urls} value
   * @returns {SubscribeService}
   */
  setUrls(value) {
    this.urls = value;
    return this;
  }

  /**
   * Initialize service.
   * @returns {Promise<void>}
   */
  async init() {
    const { Subscribe } = window;

    if (this.subscribe || !Subscribe) {
      return;
    }

    this.subscribe = Subscribe.init(this.appId);
    this.subscribe.config(this.config);

    const {
      auth: authTargets,
      signin: signinTargets,
      subscribe: subscribeTargets,
      signout: signoutTargets,
      account: accountTargets,
    } = SubscribeService.targets;

    const currentUser = await this.subscribe.getUser();

    this.user = currentUser ?? null;

    this.initElements();

    authTargets.forEach((authTarget) => {
      this.subscribe.createAuthElement('signin-with-subscribe-button', {
        target: authTarget,
      });
    });

    accountTargets.forEach((accountTarget) => {
      accountTarget.href = this.urls.account ?? '';
    });

    signinTargets.forEach((signinTarget) => {
      signinTarget.href =
        this.urls.signin?.replace('{return_url}', window.location.toString()) ?? '';
    });

    subscribeTargets.forEach((subscribeTarget) => {
      subscribeTarget.href =
        this.urls.subscribe?.replace('{return_url}', window.location.toString()) ?? '';
    });

    signoutTargets.forEach((signoutTarget) => {
      signoutTarget.addEventListener('click', async () => {
        this.user = null;
        this.update();
        this.subscribe.signout();
      });
    });

    window.dispatchEvent(new CustomEvent('poool:subscribe:ready', { detail: this }));
  }

  /**
   * Initialize auth elements.
   * @returns {void}
   */
  initElements() {
    const isLogged = !!this.user;
    const isActive = isLogged && this.user.subscription && this.user.subscription.active;

    const {
      signin: signinTargets,
      subscribe: subscribeTargets,
      signout: signoutTargets,
      account: accountTargets,
    } = SubscribeService.targets;

    signoutTargets.forEach((signoutTarget) => {
      signoutTarget.style.display = isLogged ? 'block' : 'none';
      if (!isLogged) {
        signoutTarget.setAttribute('disabled', '');
      } else {
        signoutTarget.removeAttribute('disabled');
      }
    });

    accountTargets.forEach((accountTarget) => {
      accountTarget.style.display = isLogged ? 'block' : 'none';
    });

    signinTargets.forEach((signinTarget) => {
      signinTarget.style.display = !isLogged ? 'block' : 'none';
    });

    subscribeTargets.forEach((subscribeTarget) => {
      subscribeTarget.style.display = !isActive ? 'block' : 'none';
    });
  }

  /**
   * Update service.
   * @returns {void}
   */
  update() {
    this.initElements();
    window.dispatchEvent(new CustomEvent('poool:subscribe:updated', { detail: this }));
  }

  /**
   * Run service.
   * @returns {void}
   */
  run() {
    this.load();
  }
}
