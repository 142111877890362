import { AbstractService } from './AbstractService.js';
import AccessCustomCSS from '../utils/AccessCustomCSS.js';

/**
 * @typedef {import('poool-access').Poool.AccessConfigOptions & {
 *  mailchimp_list_id?: string|null,
 *  height_calculation_method?: 'bodyOffset'|'bodyScroll'|'documentElementOffset'|'documentElementScroll'|'max'|'min'|'lowestElement'|'taggedElement',
 *  sophi_enabled?: boolean,
 *  sophi_content_id?: string|null,
 *  sophi_page_section?: string|null,
 *  sophi_visitor_type?: string|null,
 * }} AccessServiceConfig
 */

/**
 * AccessService class.
 * @augments {AbstractService<AccessServiceConfig>}
 */
export default class AccessService extends AbstractService {
  /**
   * @type {AccessServiceConfig}
   */
  static config = {
    debug: false,
    mode: 'hide',
    percent: 80,
    post_container: '[data-poool]',
    widget_container: '#poool-widget',
    app_name: '',
    force_widget: null,
    subscription_url: null,
    subscription_button_enabled: true,
    newsletter_name: null,
    newsletter_id: null,
    mailchimp_list_id: null,
    login_url: null,
    login_button_enabled: true,
    signature_enabled: true,
    user_is_premium: true,
    video_client: 'vast',
    popover_enabled: true,
    alternative_enabled: true,
    alternative_widget: 'none',
    vast: null,
    mobile_vast: null,
    context: null,
    custom_segment: null,
    cookies_enabled: false,
    consent_rejection_widget: 'subscription',
    data_policy_url: null,
    locale: 'fr',
    popover_timeout: 5000,
    disable_content_height_calculation: false,
    wait_for_dom_load: false,
    dom_load_timeout: 2000,
    paywall_load_timeout: 10000,
    track_original_action: false,
    components_load_timeout: 5000,
    ati_tracking_enabled: false,
    ati_load_timeout: 2000,
    facebook_login_enabled: false,
    google_login_enabled: false,
    ati_auto_tracking_enabled: false,
    ati_tracking_method: 'default',
    piano_auto_tracking_enabled: false,
    ga_auto_tracking_enabled: false,
    gtm_auto_tracking_enabled: false,
    gtag_auto_tracking_enabled: false,
    auto_tracking_spec_v2: false,
    ati_tag_options: {},
    stripe_public_key: null,
    popover_auto_hide: true,
    custom_return_url: null,
    cookies_domain: null,
    default_widget: 'subscription',
    fallback_widget: 'subscription',
    audit_load_timeout: 2000,
    beacons: true,
    cookies_path: '/',
    height_calculation_method: 'bodyOffset',
    sophi_enabled: false,
    sophi_content_id: null,
    sophi_page_section: null,
    sophi_visitor_type: null,
    skip_audit_loader: false,
  };

  static script = 'https://assets.poool.fr/access.min.js';

  /**
   * @type {HTMLElement|null}
   */
  accessTarget;
  /**
   * @type {HTMLElement|null}
   */
  accessContent;

  /**
   * @type {import('poool-access').Poool.AccessFactory|null}
   */
  access = null;

  pageType = 'free';

  safeContexts = new Set([]);

  user = null;

  isUpdate = false;

  /**
   * @param {string} appId
   * @param {AccessServiceConfig} config
   */
  constructor(appId, config) {
    super(appId, config);
    this.accessTarget = document.querySelector('[data-poool]');
    this.accessContent = document.querySelector('[data-poool-content]');
  }

  /**
   * Define page type.
   * @param {string} value
   * @returns {AccessService}
   */
  setPageType(value = 'free') {
    this.pageType = value;
    return this;
  }

  /**
   * Define safe context.
   * @param {Array} value
   * @returns {AccessService}
   */
  setSafeContext(value = []) {
    this.safeContexts = new Set(value);
    return this;
  }

  /**
   * Initialize service.
   * @returns {void}
   */
  init() {
    const { Access } = window;

    if (this.access || !Access || !this.accessTarget) {
      return;
    }

    if (this.user && this.user.subscription && this.user.subscription.active) {
      this.accessTarget.dataset.unlock = '';
      return;
    }

    this.access = Access.init(this.appId);
    delete this.accessTarget.dataset.unlock;

    if (this.pageType === 'free') {
      this.accessTarget.dataset.unlock = '';
    }

    let paywall = {
      target: this.accessTarget,
      pageType: this.pageType,
    };

    this.access.config(this.config);

    if (this.accessContent) {
      let percent = Number.parseInt(this.accessTarget.dataset.percent, 10);

      if (Number.isNaN(percent) || percent <= 0) {
        percent = 90;
      }

      paywall = {
        ...paywall,
        content: this.accessContent,
        mode: 'excerpt',
        percent,
      };
    }

    this.access.styles({
      layout: 'landscape',
      brand_logo: null,
      button_color: '#000000',
      button_hover_color: '#3e68ff',
      skin_color: '#F5F4EE',
      premium_color: '#3e68ff',
      custom_css: AccessCustomCSS,
    });

    this.access.on('release', () => {
      this.accessTarget.dataset.unlock = '';
    });

    this.access.on('error', (error, event) => {
      if (this.safeContexts.has(this.config.context) || this.pageType !== 'premium') {
        event.forceRelease();
      }
    });

    this.access.createPaywall(paywall);

    if (this.isUpdate) {
      return;
    }

    window.dispatchEvent(new CustomEvent('poool:access:ready', { detail: this }));
    this.isUpdate = true;
  }

  /**
   * Update service.
   * @returns {void}
   */
  update() {
    if (!this.access) {
      return;
    }

    if (this.accessTarget) {
      this.accessTarget.dataset.unlock = '';
    }

    this.access.destroy();
    this.access = null;
    this.accessContent.removeAttribute('style');
    this.accessTarget.innerHTML = '';
    this.accessContent.poool.locked = false;
    this.init();
    window.dispatchEvent(new CustomEvent('poool:access:updated', { detail: this }));
  }

  /**
   * Run service.
   * @returns {void}
   */
  run() {
    window.addEventListener('poool:subscribe:ready', ({ detail: subscribeService }) => {
      this.user = subscribeService.user;
      this.load();
    });

    window.addEventListener('poool:subscribe:updated', ({ detail: subscribeService }) => {
      this.user = subscribeService.user;
      this.update();
    });
  }
}
