import { AbstractService } from './AbstractService.js';

/**
 * @typedef {import('poool-access').Poool.AuditConfigOptions} AuditServiceConfig
 */

/**
 * AuditService class.
 * @augments {AbstractService<AuditServiceConfig>}
 */
export default class AuditService extends AbstractService {
  /**
   * @type {AuditServiceConfig}
   */
  static config = {
    debug: false,
    cookies_enabled: false,
    context: 'default',
    cookies_domain: null,
    custom_segment: null,
    custom_reader_id: null,
    user_is_premium: false,
    ati_auto_tracking_enabled: false,
    ati_tracking_method: 'default',
    piano_auto_tracking_enabled: false,
    ga_auto_tracking_enabled: false,
    gtm_auto_tracking_enabled: false,
    gtag_auto_tracking_enabled: false,
    auto_tracking_spec_v2: false,
    ati_tag_options: {},
    beacons: true,
    cookies_path: '/',
  };

  static script = 'https://assets.poool.fr/audit.min.js';

  /**
   * @type {import('poool-access').Poool.Audit|null}
   */
  audit = null;

  pageType = 'free';

  user = null;

  /**
   * Define page type.
   * @param {string} value
   * @returns {AuditService}
   */
  setPageType(value = 'free') {
    this.pageType = value;
    return this;
  }

  /**
   * Initialize service.
   * @returns {void}
   */
  init() {
    const { Audit } = window;

    if (this.audit || !Audit) {
      return;
    }

    this.audit = Audit.init(this.appId);
    this.audit.config(this.config);

    if (this.user) {
      this.audit.config(
        'user_is_premium',
        this.user && this.user.subscription && this.user.subscription.active,
      );
    }

    this.audit.sendEvent('page-view', { type: this.pageType });

    window.dispatchEvent(new CustomEvent('poool:audit:ready', { detail: this }));
  }

  /**
   * Run service.
   * @returns {void}
   */
  run() {
    window.addEventListener('poool:subscribe:ready', ({ detail: subscribeService }) => {
      this.user = subscribeService.user;
      this.load();
    });
  }
}
