import hasConsent from '#js/utils/hasConsent.js';
import AccessService from './services/AccessService.js';
import AuditService from './services/AuditService.js';
import EngageService from './services/EngageService.js';
import SubscribeService from './services/SubscribeService.js';

/**
 * @typedef {{
 *  debug?: boolean,
 *  app_name?: string,
 *  user_is_premium?: boolean,
 *  cookies_enabled?: boolean,
 *  gtag_auto_tracking_enabled?: boolean,
 *   auto_tracking_spec_v2?: boolean,
 *   context?: string|null
 * }} baseConfig
 */

/**
 * Poool
 */
export default class Poool {
  /**
   * @type {{poool: string, google_analytics: string}}
   */
  static vendorName = {
    poool: 'poool',
    google_analytics: 'google_analytics',
  };

  /**
   * @type {baseConfig}
   */
  static config = {
    debug: false,
    app_name: '',
    user_is_premium: false,
    cookies_enabled: false,
    gtag_auto_tracking_enabled: false,
    auto_tracking_spec_v2: false,
    context: null,
  };

  /**
   * @type {{subscribe: string, signin: string, account: string}}
   */
  static urls = {
    subscribe: 'https://abonnement.maddyness.com/?returnUrl={return_url}',
    signin: 'https://abonnement.maddyness.com/me?returnUrl={return_url}',
    account: 'https://abonnement.maddyness.com/me',
  };

  /**
   * @type {HTMLElement|null}
   */
  static accessTarget = document.querySelector('[data-poool]');

  appId = null;

  services = [];

  __context = null;

  __pageType = null;

  __consents = null;

  /**
   * @returns {string}
   */
  get currentUrlEncoded() {
    return encodeURI(window.location.href);
  }

  /**
   * @returns {string}
   */
  get formattedSubscribeUrl() {
    return Poool.urls.subscribe.replace('{return_url}', this.currentUrlEncoded);
  }

  /**
   * @returns {string}
   */
  get formattedSigninUrl() {
    return Poool.urls.signin.replace('{return_url}', this.currentUrlEncoded);
  }

  /**
   * @returns {string}
   */
  get context() {
    if (this.__context) {
      return this.__context;
    }

    this.__context =
      Poool.accessTarget?.dataset?.poool && Poool.accessTarget.dataset.poool.length > 0
        ? Poool.accessTarget.dataset.poool
        : 'default';

    return this.__context;
  }

  /**
   * @returns {string}
   */
  get pageType() {
    if (this.__pageType) {
      return this.__pageType;
    }
    this.__pageType =
      Poool.accessTarget?.dataset?.type && Poool.accessTarget.dataset.type.length > 0
        ? Poool.accessTarget.dataset.type
        : this.context
          ? 'premium'
          : 'free';

    return this.__pageType;
  }

  /**
   * @returns {{poool: boolean, google_analytics: boolean}}
   */
  get consents() {
    if (this.__consents) {
      return this.__consents;
    }

    this.__consents = {
      [Poool.vendorName.poool]: hasConsent(Poool.vendorName.poool),
      [Poool.vendorName.google_analytics]: hasConsent(Poool.vendorName.google_analytics),
    };

    return this.__consents;
  }

  /**
   * @param {string} appId
   * @param {string|null} appName
   * @param {boolean} debug
   */
  constructor(appId, appName = null, debug = false) {
    if (!appId || !appId.length) {
      throw new Error('Poool app ID is required');
    }

    this.appId = appId;
    this.config = {
      ...Poool.config,
      debug,
      app_name: appName,
      cookies_enabled: this.consents[Poool.vendorName.poool],
      gtag_auto_tracking_enabled: this.consents[Poool.vendorName.google_analytics],
      auto_tracking_spec_v2: this.consents[Poool.vendorName.google_analytics],
      context: this.context,
    };

    this.init();
    this.run();
  }

  /**
   * Initilize Poool.
   * @returns {void}
   */
  init() {
    const access = new AccessService(this.appId, {
      ...this.config,
      context: this.context,
      subscription_url: Poool.urls.subscribe,
      login_url: Poool.urls.signin,
    });
    access.setSafeContext(['homepage']).setPageType(this.pageType);

    this.services.push(access);

    const audit = new AuditService(this.appId, this.config);
    audit.setPageType(this.pageType);
    this.services.push(audit);

    const engage = new EngageService(this.appId, {
      ...this.config,
      gtagAutoTrackingEnabled: this.config.gtag_auto_tracking_enabled,
    });
    this.services.push(engage);

    const subscribe = new SubscribeService(this.appId, this.config);
    subscribe.setUrls({
      account: Poool.urls.account,
      signin: this.formattedSigninUrl,
      subscribe: this.formattedSubscribeUrl,
    });
    this.services.push(subscribe);
    this.maybeRefresh();
  }

  /**
   * Run all services.
   * @returns {void}
   */
  run() {
    this.services.forEach((service) => service.run());
  }

  /**
   * Maybe refresh the page after consent change.
   * @returns {void}
   */
  maybeRefresh() {
    setTimeout(() => {
      const { __tcfapi } = window;
      if (__tcfapi) {
        __tcfapi('addEventListener', 2, (tcData, success) => {
          if (!success || tcData.eventStatus !== 'useractioncomplete') {
            return;
          }

          const consentStatus =
            tcData?.purpose?.consents?.['2'] &&
            tcData?.purpose?.consents?.['3'] &&
            tcData?.purpose?.consents?.['4'] &&
            tcData?.purpose?.consents?.['5'] &&
            tcData?.purpose?.consents?.['6'] &&
            tcData?.purpose?.consents?.['7'] &&
            tcData?.specialFeatureOptins?.['1'] &&
            tcData?.specialFeatureOptins?.['2'];

          if (
            consentStatus === this.consents.poool &&
            hasConsent(Poool.vendorName.poool) === this.consents.poool
          ) {
            return;
          }

          window.location.reload();
        });
      }
    }, 1000);
  }
}
