import scriptLoader from '#js/utils/scriptLoader.js';

/**
 * @typedef {{
 *   debug?: boolean;
 * }} AbstractServiceConfig
 */

/**
 * AbstractService class.
 * @template {AbstractServiceConfig} [T=AbstractServiceConfig]
 */
export class AbstractService {
  /**
   * Service configuration
   * @type {AbstractServiceConfig}
   */
  static config = {
    debug: false,
  };

  /**
   * Script src.
   * @type {string}
   */
  static script;

  /**
   * Poool application ID.
   * @type {string}
   */
  appId;

  /**
   * @type {AbstractServiceConfig & T}
   */
  config;

  /**
   * Class constructor.
   * @param {string} appId  Poool application ID.
   * @param {T} config
   */
  constructor(appId, config) {
    this.appId = appId;
    const ctor = /** @type {typeof AbstractService} */ (this.constructor);
    this.config = { ...ctor.config, ...config };
  }

  /**
   * Init the service.
   */
  init() {
    // This method must be implemented.
  }

  /**
   * Run the service.
   */
  run() {
    // This method must be implemented.
  }

  /**
   * Load script.
   * @returns {void}
   */
  load() {
    const ctor = /** @type {typeof AbstractService} */ (this.constructor);

    if (!ctor.script) {
      return;
    }

    scriptLoader(ctor.script, this.init.bind(this));
  }
}
