import { AbstractService } from './AbstractService.js';

/**
 * @typedef {import('poool-engage').Poool.EngageConfigOptions} EngageServiceConfig
 */

/**
 * EngageService class.
 * @augments {AbstractService<EngageServiceConfig>}
 */
export default class EngageService extends AbstractService {
  /**
   * @type {EngageServiceConfig}
   */
  static config = {
    debug: false,
    autoPageViews: true,
    stripePublicKey: null,
    locale: 'en',
    pianoAutoTrackingEnabled: false,
    gtagAutoTrackingEnabled: false,
  };

  static script = 'https://assets.poool.fr/engage.min.js';

  /**
   * @type {import('poool-engage').Poool.Engage|null}
   */
  engage = null;

  user = null;

  /**
   * Initialize service.
   * @returns {void}
   */
  init() {
    const { Engage } = window;

    if (this.engage || !Engage) {
      return;
    }

    this.engage = Engage.init(this.appId);
    this.engage.config(this.config);
    this.engage.autoCreate({
      filters: [
        this.user && this.user.subscription && this.user.subscription.active
          ? 'user_subscribed'
          : 'user_unsubscribed',
      ],
    });

    window.dispatchEvent(new CustomEvent('poool:engage:ready', { detail: this }));
  }

  /**
   * Run service.
   * @returns {void}
   */
  run() {
    window.addEventListener('poool:subscribe:ready', ({ detail: subscribeService }) => {
      this.user = subscribeService.user;
      this.load();
    });
  }
}
