import { getCookie } from './cookies.js';

/**
 * Check if consent exists for vendor.
 * @param {string} vendor
 * @returns {boolean}
 */
export default function hasConsent(vendor) {
  return getCookie('axeptio_authorized_vendors')?.includes(vendor) ?? false;
}
